const getPuzzle = async (wordCount) => {
    const response = await fetch(`//puzzle.mead.io/puzzle?wordCount=${wordCount}`)
    if (response.status === 200) {
        const data = await response.json()
        return data.puzzle
    } else {
        throw new Error('Unable to fetch puzzle.')
    }
}

const getCountryInformation = async (countryCode) => {
    const response = await fetch('//restcountries.eu/rest/v2/all')

    if (response.status === 200) {
        const data = await response.json()
        return data.find((country) => country.alpha2Code === countryCode)
    } else {
        throw new Error('Unable to fetch data')
    }
}

const getIpInfo = async () => {
    const response = await fetch('//ipinfo.io/json?token=3d5860f587d197')

    if (response.status === 200) {
        return response.json()
    } else {
        throw new Error('Unable to fetch location')
    }
}

export { getPuzzle as default }
// const getPuzzle = (wordCount) => {
//     return fetch(`http://puzzle.mead.io/puzzle?wordCount=${wordCount}`).then((response) => {
//         if (response.status === 200) {
//             return response.json()
//         } else {
//             throw new Error('Unable to fetch puzzle.')
//         }
//     }).then((data) => {
//         return data.puzzle
//     })
// }

// const getPuzzle = (wordCount) => new Promise((resolve, reject) =>  {
//     const request = new XMLHttpRequest()

//     request.addEventListener('readystatechange', (e) => {
//         if (e.target.readyState === 4 && e.target.status === 200) {
//             const data = JSON.parse(e.target.responseText)
//             resolve(data.puzzle)
//         } else if (e.target.readyState === 4 )
//             reject('An error has occured')
//     })

//     request.open('GET', `http://puzzle.mead.io/puzzle?wordCount=${wordCount}`)
//     request.send()
// })

// const getCountryInformation = (countryCode) => new Promise((resolve, reject) => {
//     const requestCountry = new XMLHttpRequest()

//     requestCountry.addEventListener('readystatechange', (e) => {
//         if (e.target.readyState === 4 && e.target.status === 200) {
//             const countryData = JSON.parse(e.target.responseText)
//             const country = countryData.find((country) => country.alpha2Code === countryCode)
//             resolve(country)
//         } else if (e.target.readyState === 4)
//             reject('Unable to fetch data.')    
//     })

//     requestCountry.open('GET', 'http://restcountries.eu/rest/v2/all')
//     requestCountry.send()
// })

// const getCountryInformation = (countryCode) => {
//     return fetch('http://restcountries.eu/rest/v2/all').then((response) => {
//         if (response.status === 200) {
//             return response.json()
//         } else {
//             throw new Error('Unable to get country information')
//         }
//     }).then((data) => data.find((country) => country.alpha2Code === countryCode)
//     )}

// const getIpInfo = () => {
//     return fetch('http://ipinfo.io/json?token=3d5860f587d197').then((response) => {
//         if (response.status === 200) {
//             return response.json()
//         } else {
//             throw new Error('Unable to get ip location.')
//         }
//     })
// }

//From LiveShare Session
// const getCountry = async (countryCode) => {
//     const response = await fetch('http://restcountries.eu/rest/v2/all')

//     if (response.status === 200) {
//         const data = await response.json()
//         return data.find((country) => country.alpha2Code === countryCode)
//     } else {
//         throw new Error('Unable to fetch data')
//     }
// }

// const getLocation = async () => {
//     const response = await fetch('http://ipinfo.io/json?token=3d5860f587d197')

//     if (response.status === 200) {
//         return response.json()
//     } else {
//         throw new Error('Unable to fetch location')
//     }
// }